const tableData = {
  thead: ["Structure", "Weapon", "Quantity"],
  tbody: [
    ["Tek Forcefield", "C4 Charge", "318"],
    ["", "Rocket Propelled Grenade", "420"],
    ["", "Tek Rifle Element", "27"],
    ["", "Tek Tapejara Element", "29"],
    ["", "Other Tek Saddles", "20"],
    ["", "Tek Grenade", "650"],
    ["", "Cannonball", "6250"],
    ["Tek Generator", "C4 Charge", "32"],
    ["", "Rocket Propelled Grenade", "42"],
    ["", "Tek Rifle Element", "3"],
    ["", "Tek Tapejara Element", "3"],
    ["", "Other Tek Saddles", "2"],
    ["", "Tek Grenade", "65"],
    ["", "Cannonball", "625"],
    ["Large Tek Wall", "C4 Charge", "23"],
    ["", "Rocket Propelled Grenade", "31"],
    ["", "Tek Rifle Element", "2"],
    ["", "Tek Tapejara Element", "2"],
    ["", "Other Tek Saddles", "2"],
    ["", "Tek Grenade", "47"],
    ["", "Cannonball", "450"],
    ["Tek Foundation", "C4 Charge", "19"],
    ["", "Rocket Propelled Grenade", "26"],
    ["", "Tek Rifle Element", "2"],
    ["", "Tek Tapejara Element", "2"],
    ["", "Other Tek Saddles", "2"],
    ["", "Tek Grenade", "39"],
    ["", "Cannonball", "375"],
    ["Tek Wall/Ceiling", "C4 Charge", "13"],
    ["", "Rocket Propelled Grenade", "17"],
    ["", "Tek Rifle Element", "2"],
    ["", "Tek Tapejara Element", "2"],
    ["", "Other Tek Saddles", "1"],
    ["", "Tek Grenade", "26"],
    ["", "Cannonball", "250"],
    ["Tek Pillar", "C4 Charge", "9"],
    ["", "Rocket Propelled Grenade", "11"],
    ["", "Tek Rifle Element", "1"],
    ["", "Tek Tapejara Element", "1"],
    ["", "Other Tek Saddles", "1"],
    ["", "Tek Grenade", "17"],
    ["", "Cannonball", "163"],
    ["Metal Cliff Platform", "C4 Charge", "29"],
    ["", "Rocket Propelled Grenade", "46"],
    ["", "Tek Rifle Element", "69"],
    ["", "Tek Tapejara Element", "56"],
    ["", "Other Tek Saddles", "39"],
    ["", "Tek Grenade", "1695"],
    ["", "Cannonball", "282"],
    ["Vault", "C4 Charge", "15"],
    ["", "Rocket Propelled Grenade", "23"],
    ["", "Tek Rifle Element", "35"],
    ["", "Tek Tapejara Element", "28"],
    ["", "Other Tek Saddles", "20"],
    ["", "Tek Grenade", "848"],
    ["", "Cannonball", "141"],
    ["Large Metal Wall", "C4 Charge", "6"],
    ["", "Rocket Propelled Grenade", "9"],
    ["", "Tek Rifle Element", "13"],
    ["", "Tek Tapejara Element", "10"],
    ["", "Other Tek Saddles", "7"],
    ["", "Tek Grenade", "306"],
    ["", "Cannonball", "51"],
    ["Metal foundation", "C4 Charge", "5"],
    ["", "Rocket Propelled Grenade", "7"],
    ["", "Tek Rifle Element", "11"],
    ["", "Tek Tapejara Element", "9"],
    ["", "Other Tek Saddles", "6"],
    ["", "Tek Grenade", "255"],
    ["", "Cannonball", "43"],
    ["Metal wall/ceiling", "C4 Charge", "3"],
    ["", "Rocket Propelled Grenade", "5"],
    ["", "Tek Rifle Element", "7"],
    ["", "Tek Tapejara Element", "6"],
    ["", "Other Tek Saddles", "4"],
    ["", "Tek Grenade", "170"],
    ["", "Cannonball", "29"],
    ["Metal Pillar", "C4 Charge", "2"],
    ["", "Rocket Propelled Grenade", "3"],
    ["", "Tek Rifle Element", "5"],
    ["", "Tek Tapejara Element", "4"],
    ["", "Other Tek Saddles", "3"],
    ["", "Tek Grenade", "111"],
    ["", "Cannonball", "19"],
    ["Metal Spikes", "C4 Charge", "1"],
    ["", "Rocket Propelled Grenade", "1"],
    ["", "Tek Rifle Element", "1"],
    ["", "Tek Tapejara Element", "1"],
    ["", "Other Tek Saddles", "1"],
    ["", "Tek Grenade", "5"],
    ["", "Cannonball", "2"],
  ],
};

export default tableData;

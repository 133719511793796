const tableData = {
  thead: ["Boss", "Difficulty", "Item engram"],
  tbody: [
    ["Broodmother Lysrix", "ALPHA", "Mosasaur Tek Saddle"],
    ["", "", "Tek Helmet"],
    ["", "", "Tek Turret"],   
    ["", "BETA", "Sloped Tek Roof"],
    ["", "", "Sloped Tek Wall Left"],    
    ["", "", "Sloped Tek Wall Right"],
    ["", "", "Tek Boots"],
    ["", "", "Tek Catwalk"],
    ["", "", "Tek Ceiling"],    
    ["", "", "Tek Fence Foundation"],
    ["", "", "Tek Ladder"],
    ["", "", "Tek Pillar"],
    ["", "", "Tek Railing"],    
    ["", "", "Tek Ramp"],
    ["", "", "Tek Staircase"],
    ["", "", "Tek Wall"],
    ["", "GAMMA", "Tek Foundation"],    
    ["", "", "Tek Replicator"],
    ["", "", "Tek Triangle Ceiling"],
    ["", "", "Tek Triangle Foundation"],
    ["", "", "Tek Triangle Roof"],    
    ["Megapithecus", "ALPHA", "Large Tek Wall"],
    ["", "", "Rex Tek Saddle"],
    ["", "", "Tek Grenade"],
    ["", "BETA", "Tek Dinosaur Gate"],    
    ["", "", "Tek Dinosaur Gateway"],
    ["", "", "Tek Door"],
    ["", "", "Tek Doorframe"],
    ["", "", "Tek Generator"],    
    ["", "", "Tek Rifle"],
    ["", "", "Tek Trough"],   
    ["", "GAMMA", "Tek Gauntlets"],
    ["", "", "Tek Hatchframe"],
    ["", "", "Tek Replicator"],
    ["", "", "Tek Trapdoor"],    
    ["", "", "Tek Window"],
    ["", "", "Tek Windowframe"],
    ["Dragon", "ALPHA", "Cloning Chamber"],
    ["", "", "Tek Chestpiece"],    
    ["", "", "Tek Teleporter"],
    ["", "", "Vacuum Compartment Moonpool"],
    ["", "BETA", "Tek Dedicated Storage"],
    ["", "", "Tek Double Door"],
    ["", "", "Tek Double Doorframe"],    
    ["", "", "Tek Fence Support"],
    ["", "", "Tek Forcefield"],
    ["", "", "Tek Stairs"],
    ["", "", "Tek Transmitter"],    
    ["", "", "Vacuum Compartment"],
    ["", "GAMMA", "Behemoth Tek Gate"],
    ["", "", "Behemoth Tek Gateway"],    
    ["", "", "Megalodon Tek Saddle"],
    ["", "", "Tapejara Tek Saddle"],
    ["", "", "Tek Leggings"],
    ["", "", "Tek Replicator"],
    ["Manticore", "ALPHA", "Cloning Chamber"],
    ["", "", "Tek Forcefield"],
    ["", "", "Tek Grenade"],
    ["", "", "Tek Teleporter"],    
    ["", "BETA", "Tapejara Tek Saddle"],    
    ["", "", "Tek Dedicated Storage"],
    ["", "", "Tek Rifle"],
    ["", "", "Tek Shield"],  
    ["", "GAMMA", "Tek Gauntlets"],
    ["", "", "Tek Generator"],
    ["", "", "Tek Leggings"],
    ["", "", "Tek Replicator"],    
    ["", "", "Tek Trough"],
    ["Rockwell (Aberration)", "ALPHA", "Cloning Chamber"],
    ["", "", "Tek Forcefield"],
    ["", "", "Tek Railgun"],    
    ["", "", "Tek Teleporter"],
    ["", "BETA", "Rock Drake Tek Saddle"],
    ["", "", "Tek Boots"],
    ["", "", "Tek Chestpiece"],    
    ["", "", "Tek Dedicated Storage"],
    ["", "", "Tek Rifle"],
    ["", "GAMMA", "Tek Generator"],
    ["", "", "Tek Leggings"],
    ["", "", "Tek Replicator"],    
    ["", "", "Tek Sleeping Pod"],
    ["", "", "Tek Trough"],
    ["Moeder (Genesis 1)", "ALPHA", "Tek Forcefield"],
    ["", "", "Tek Grenade Launcher"],
    ["", "", "Tek Teleporter"],    
    ["", "BETA", "Tek Boots"],    
    ["", "", "Tek Gauntlets"],
    ["", "", "Tek Helmet"],
    ["", "GAMMA", "Tek Generator"],
    ["", "", "Tek Sensor"],
    ["", "", "Tek Trough"],    
    ["Corrupted Master Controller", "ALPHA", "Cruise Missile"],
    ["", "", "Tek Chestpiece"],
    ["", "BETA", "Astrocetus Tek Saddle"],    
    ["", "", "Tek Leggings"],
    ["", "", "Tek Shoulder Cannon"],   
    ["", "GAMMA", "Tek Claws"],
    ["", "", "Tek Dedicated Storage"],
    ["", "", "Tek Replicator"],
    ["", "", "Unassembled TEK Hover Skiff"],    
    ["Crystal Wyvern Queen", "ALPHA", "Cloning Chamber"],
    ["", "", "Large Tek Wall"],
    ["", "", "Tek Chestpiece"],
    ["", "", "Tek Teleporter"],    
    ["", "", "Vacuum Compartment Moonpool"],
    ["", "BETA", "Tek Ceiling"],
    ["", "", "Tek Dedicated Storage"],
    ["", "", "Tek Double Door"],    
    ["", "", "Tek Double Doorframe"],
    ["", "", "Tek Fence Foundation"],
    ["", "", "Tek Fence Support"],
    ["", "", "Tek Forcefield"],    
    ["", "", "Tek Rifle"],
    ["", "", "Tek Stairs"],
    ["", "", "Tek Transmitter"],
    ["", "", "Tek Wall"],
    ["", "", "Vacuum Compartment"],    
    ["", "GAMMA", "Behemoth Tek Gate"],
    ["", "", "Behemoth Tek Gateway"],    
    ["", "", "Tek Foundation"],
    ["", "", "Tek Gauntlets"],
    ["", "", "Tek Generator"],
    ["", "", "Tek Leggings"],    
    ["", "", "Tek Replicator"],
    ["", "", "Tek Trough"],
    ["", "", "Tek Window"],
    ["", "", "Tek Windowframe"],      
    ["Rockwell Prime (Genesis 2)", "BETA", "Tek Chestpiece"],
    ["", "", "Tek Helmet"],
    ["", "", "Tek Rifle"],
    ["", "", "Tek Shield"],    
    ["", "", "Tek Teleporter"],
    ["", "", "Tek Turret"],
    ["", "GAMMA", "Tek Boots"],    
    ["", "", "Tek Dedicated Storage"],
    ["", "", "Tek Gauntlets"],
    ["", "", "Tek Generator"],
    ["", "", "Tek Leggings"],    
    ["", "", "Tek Replicator"],
    ["", "", "Tek Trough"],
    ["", "", "Unassembled Exo-Mek"],
    ["The Center Arena", "ALPHA", "Mosasaur Tek Saddle"],
    ["", "", "Tek Grenade"],
    ["", "", "Tek Helmet"],
    ["", "", "Tek Turret"],
    ["", "BETA", "Sloped Tek Roof"],
    ["", "", "Sloped Tek Wall Left"],
    ["", "", "Sloped Tek Wall Right"],
    ["", "", "Tek Boots"],
    ["", "", "Tek Catwalk"],
    ["", "", "Tek Ceiling"],
    ["", "", "Tek Dinosaur Gate"],
    ["", "", "Tek Dinosaur Gateway"],
    ["", "", "Tek Door"],
    ["", "", "Tek Doorframe"],
    ["", "", "Tek Fence Foundation"],
    ["", "", "Tek Generator"],
    ["", "", "Tek Ladder"],
    ["", "", "Tek Pillar"],
    ["", "", "Tek Railing"],
    ["", "", "Tek Ramp"],
    ["", "", "Tek Rifle"],
    ["", "", "Tek Staircase"],
    ["", "", "Tek Trough"],
    ["", "", "Tek Wall"],
    ["", "GAMMA", "Tek Foundation"],
    ["", "", "Tek Gauntlets"],
    ["", "", "Tek Hatchframe"],
    ["", "", "Tek Replicator"],
    ["", "", "Tek Trapdoor"],
    ["", "", "Tek Window"],
    ["", "", "Tek Windowframe"],
    ["Ragnarok Arena", "ALPHA", "Cloning Chamber"],
    ["", "", "Tek Chestpiece"],
    ["", "", "Tek Grenade"],
    ["", "", "Tek Shield"],
    ["", "", "Tek Teleporter"],
    ["", "", "Vacuum Compartment Moonpool"],
    ["", "BETA", "Tek Forcefield"],
    ["", "", "Tek Rifle"],
    ["", "", "Tek Sword"],
    ["", "", "Tek Transmitter"],
    ["", "", "Vacuum Compartment"],
    ["", "GAMMA", "Behemoth Tek Gate"],
    ["", "", "Behemoth Tek Gateway"],
    ["", "", "Megalodon Tek Saddle"],
    ["", "", "Rex Tek Saddle"],
    ["", "", "Tapejara Tek Saddle"],
    ["", "", "Tek Gauntlets"],
    ["", "", "Tek Generator"],
    ["", "", "Tek Leggings"],
    ["", "", "Tek Light"],
    ["", "", "Tek Replicator"],
    ["", "", "Tek Trough"],
    ["Valguero Arena", "ALPHA", "Cloning Chamber"],
    ["", "", "Tek Chestpiece"],
    ["", "", "Tek Grenade"],
    ["", "", "Tek Teleporter"],
    ["", "", "Vacuum Compartment Moonpool"],
    ["", "BETA", "Tek Dinosaur Gate"],
    ["", "", "Tek Dinosaur Gateway"],
    ["", "", "Tek Door"],
    ["", "", "Tek Doorframe"],
    ["", "", "Tek Forcefield"],
    ["", "", "Tek Leggings"],
    ["", "", "Tek Rifle"],
    ["", "", "Tek Transmitter"],
    ["", "", "Vacuum Compartment"],
    ["", "GAMMA", "Behemoth Tek Gate"],
    ["", "", "Behemoth Tek Gateway"],
    ["", "", "Megalodon Tek Saddle"],
    ["", "", "Rex Tek Saddle"],
    ["", "", "Tapejara Tek Saddle"],
    ["", "", "Tek Gauntlets"],
    ["", "", "Tek Generator"],
    ["", "", "Tek Hatchframe"],
    ["", "", "Tek Replicator"],
    ["", "", "Tek Trapdoor"],
    ["", "", "Tek Trough"],
    ["", "", "Tek Window"],
    ["", "", "Tek Windowframe"],
    ["Desert Titan", "GAMMA", "Tek Boots"],
    ["", "", "Tek Dedicated Storage"],
    ["", "", "Tek Generator"],
    ["", "", "Tek Replicator"],
    ["", "", "Tek Railgun"],
    ["", "", "Tek Trough"],
    ["Forest Titan", "GAMMA", "Tek Gauntlets"],
    ["", "", "Tek Leggings"],
    ["", "", "Tek Replicator"],
    ["", "", "Tek Teleporter"],
    ["Ice Titan", "GAMMA", "Tapejara Tek Saddle"],
    ["", "", "Tek Chestpiece"],
    ["", "", "Tek Forcefield"],
    ["", "", "Tek Helmet"],
    ["", "", "Tek Replicator"],
    ["", "", "Tek Rifle"],
  ],
};

export default tableData;

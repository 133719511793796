const tableData = {
  thead: ["Mission", "Type", "Loot table"],
  tbody: [
    ["Bulbdog Fetch", "GAMMA", "Ghillie Chest"],
    ["", "", "Chitin Boots"],
    ["", "", "Ghillie Gloves"],
    ["", "", "Simple Pistol"],
    ["", "", "Metal Sickle"],
    ["", "", "Pachyrhino Saddle"],
    ["", "", "Phiomia Saddle"],
    ["", "", "Iguanodon Saddle"],
    ["", "", "Morellatops Saddle"],
    ["", "BETA", "Flak Legs"],
    ["", "", "Chitin Helmet"],
    ["", "", "Cloth Boots"],
    ["", "", "Fab Sniper"],
    ["", "", "Chitin Boots"],
    ["", "", "Charge Lantern"],
    ["", "", "Deinonychus saddle"],
    ["", "", "Anky Saddle"],
    ["", "", "Velonasaur Saddle"],
    ["", "", "Castoroides Saddle"],
    ["", "", "Baryonyx Saddle"],
    ["", "ALPHA", "Tek Boots"],
    ["", "", "Tek Shoulder Cannon"],
    ["", "", "Roll Rat Saddle"],
    ["", "", "Tapejara Saddle"],
    ["", "", "Rock Drake Saddle"],
    ["Choose Your Own Adventure", "GAMMA", "Hide Shirt"],
    ["", "", "Desert Cloth Boots"],
    ["", "", "Chitin Boots"],
    ["", "", "Cloth Boots"],
    ["", "", "Wood Shield"],
    ["", "", "Simple Pistol"],
    ["", "BETA", "Compound Bow"],
    ["", "", "Riot Chest"],
    ["", "", "Flak Boots"],
    ["", "", "Scuba Leggings"],
    ["", "", "Fur Cap"],
    ["", "", "Flamethrower"],
    ["", "", "Ghillie Chest"],
    ["", "", "Hazard Suit"],
    ["", "", "Lance"],
    ["", "ALPHA", "MRLM Tek Module"],
    ["", "", "Tek Rifle"],
    ["", "", "Tek Railgun"],
    ["Code Red", "GAMMA", "Desert Cloth Gloves"],
    ["", "", "Desert Cloth Boots"],
    ["", "", "Fur Gauntlets"],
    ["", "", "Ghillie Legs"],
    ["", "", "Hazard Suit Gloves"],
    ["", "", "Hide Shirt"],
    ["", "", "Metal Hatcher"],
    ["", "BETA", "Fabricated Pistol"],
    ["", "", "Pump-Action Shotgun"],
    ["", "", "Scuba Mask"],
    ["", "ALPHA", "Hazard Suit Boots"],
    ["", "", "Tek Gloves"],
    ["", "", "Tek Claws"],
    ["Ferox Cull", "GAMMA", "Frog Saddle"],
    ["", "", "Ghillie Legs"],
    ["", "", "Chitin Gloves"],
    ["", "", "Hide Pants"],
    ["", "", "Cloth Hat"],
    ["", "", "Hide Hat"],
    ["", "", "Cloth Chest"],
    ["", "", "Lymantria Saddle"],
    ["", "", "Fur Boots"],
    ["", "", "Wood Shield"],
    ["", "", "Gacha Saddle"],
    ["", "BETA", "Carno Saddle"],
    ["", "", "Riot Helmet"],
    ["", "", "Araneo Saddle"],
    ["", "", "Pteranodon Saddle"],
    ["", "", "Flak Gloves"],
    ["", "", "Thorny Dragon Saddle"],
    ["", "", "Flak Boots"],
    ["", "", "Megalosaurus Saddle"],
    ["", "", "Minigun"],
    ["", "ALPHA", "Fabricated Pistol"],
    ["", "", "Tek Gloves"],
    ["", "", "Liopleurodon Saddle"],
    ["", "", "Megachelon Saddle"],
    ["", "", "Paracer Platform Saddle"],
    ["", "", "Plesiosaur Platform"],
    ["", "", "Riot Helm"],
    ["", "", "Yuty Saddle"],
    ["", "", "Basilisk Saddle"],
    ["Life Support", "GAMMA", "Chitin Helmet"],
    ["", "", "Longneck Rifle"],
    ["", "", "Cloth Shirt"],
    ["", "", "Scuba Flippers"],
    ["", "", "Desert Cloth Goggles"],
    ["", "", "Pike"],
    ["", "", "Fur Boots"],
    ["", "", "Hide Hat"],
    ["", "", "Equus Saddle"],
    ["", "BETA", "Fab Sniper"],
    ["", "", "Ghillie Mask"],
    ["", "", "Hazard Suit Shirt"],
    ["", "", "Mining Drill"],
    ["", "", "Metal Shield"],
    ["", "ALPHA", "Tek Grenade Launcher"],
    ["", "", "Tek Helmet"],
    ["", "", "Flak Legs"],
    ["", "", "Plesiosaur Platform"],
    ["Maewing Poach", "GAMMA", "Chitin Chestpiece"],
    ["", "", "Hide Gloves"],
    ["", "", "Chitin Legs"],
    ["", "", "Maewing Saddle"],
    ["", "", "Cloth Gloves"],
    ["", "", "Stone Hatchet"],
    ["", "", "Doedicurus Saddle"],
    ["", "", "Fur Chest"],
    ["", "", "Fur Legs"],
    ["", "BETA", "Flak Chest"],
    ["", "", "Wooly Rhino Saddle"],
    ["", "", "Flak Helm"],
    ["", "", "Metal Shield"],
    ["", "", "Sabertooth Saddle"],
    ["", "", "Snow Owl Saddle"],
    ["", "", "Thylacoleo Saddle"],
    ["", "ALPHA", "Electric Prod"],
    ["", "", "Tek Chest"],
    ["", "", "Longneck Rifle"],
    ["", "", "Tek Shield"],
    ["", "", "Kapro Saddle"],
    ["", "", "Tek Sword"],
    ["", "", "Bronto Platform Saddle"],
    ["Paracer Roundup", "GAMMA", "Araneo Saddle"],
    ["", "", "Fur Cap"],
    ["", "", "Bow"],
    ["", "", "Slingshot"],
    ["", "", "Cloth Hat"],
    ["", "", "Stego Saddle"],
    ["", "", "Cloth Pants"],
    ["", "", "Trike Saddle"],
    ["", "", "Dunkle Saddle"],
    ["", "", "Hide Chest"],
    ["", "BETA", "Argentavis Saddle"],
    ["", "", "Allosaurus Saddle"],
    ["", "", "Manta Saddle"],
    ["", "", "Parasaur Saddle"],
    ["", "", "Ravager Saddle"],
    ["", "", "Riot Gloves"],
    ["", "ALPHA", "Chainsaw"],
    ["", "", "Tek Claws"],
    ["", "", "Magmasaur Saddle"],
    ["", "", "Tek Helmet"],
    ["", "", "Mantis Saddle"],
    ["", "", "Tek Legs"],
    ["", "", "Pelagornis Saddle"],
    ["", "", "Shotgun"],
    ["Shadowmane Prowl", "GAMMA", "Assault Rifle"],
    ["", "", "Longneck Rifle"],
    ["", "", "Arthropluera Saddle"],
    ["", "", "Raptor Saddle"],
    ["", "", "Fur Gauntlets"],
    ["", "", "Sarco Saddle"],
    ["", "", "Gallimimus Saddle"],
    ["", "", "Ghillie Boots"],
    ["", "", "Ghillie Mask"],
    ["", "BETA", "Bronto Saddle"],
    ["", "", "Pteranodon Saddle"],
    ["", "", "Compound Bow"],
    ["", "", "Riot Helmet"],
    ["", "", "Equus Saddle"],
    ["", "", "Riot Leggings"],
    ["", "", "Fur Gauntlets"],
    ["", "", "Megatherium Saddle"],
    ["", "", "Paracer Saddle"],
    ["", "ALPHA", "Dire Bear Saddle"],
    ["", "", "Tek Grenade Launcher"],
    ["", "", "Ghillie Boots"],
    ["", "", "Tek Bow"],
    ["", "", "Giga Saddle"],
    ["", "", "Tek Shield"],
    ["", "", "Lance"],
    ["", "", "Titanosaur Platform"],
    ["", "", "Spino Saddle"],
    ["Star Dolphin", "GAMMA", "Chitin Chestpiece"],
    ["", "", "Desert Cloth Pants"],
    ["", "", "Ghillie Gloves"],
    ["", "", "Assault Rifle"],
    ["", "", "Hide Gloves"],
    ["", "BETA", "Charged Lantern"],
    ["", "", "Fur Chest"],
    ["", "", "Pike"],
    ["", "", "Heavy Miner's Helmet"],
    ["", "", "Scuba Chest"],
    ["", "", "Electric Prod"],
    ["", "ALPHA", "Mining Drill"],
    ["", "", "Longneck"],
    ["", "", "Tek Grenade Launcher"],
    ["", "", "Tek Bow"],
    ["", "", "Metal Shield"],
    ["", "", "Cloth Shirt"],
    ["", "", "Ghillie Mask"],
    ["", "", "Scuba Flippers"],
    ["Survive the Ark", "GAMMA", "Sword"],
    ["", "", "Chitin Legs"],
    ["", "", "Hide Boots"],
    ["", "BETA", "Riot Shield"],
    ["", "", "Basilosaurus Saddle"],
    ["", "", "Fur Legs"],
    ["", "", "Hazard Suit Helm"],
    ["", "", "Riot Boots"],
    ["", "ALPHA", "Flak Gloves"],
    ["", "", "Tek Chest"],
    ["", "", "Tek Shield"],
    ["", "", "Tek Pistol"],
    ["", "", "Shoulder Cannon"],
    ["Velonosaur Stalk", "GAMMA", "Carbonemys Saddle"],
    ["", "", "Crossbow"],
    ["", "", "Pachy Saddle"],
    ["", "", "Diplo Saddle"],
    ["", "", "Sword"],
    ["", "", "Stone Hatchet"],
    ["", "", "Hide Boots"],
    ["", "", "Megaloceros Saddle"],
    ["", "", "Scorpion Saddle"],
    ["", "BETA", "Calico Saddle"],
    ["", "", "Pump-Action Shotgun"],
    ["", "", "Gasbag Saddle"],
    ["", "", "Daeodon Saddle"],
    ["", "", "Hyaenodon Meatpack"],
    ["", "", "Liopleurodon Saddle"],
    ["", "", "Riot Shield"],
    ["", "ALPHA", "Karkinos Saddle"],
    ["", "", "Tek Rifle"],
    ["", "", "Megalania Saddle"],
    ["", "", "Mosasaur Platform"],
    ["", "", "Quetz Platform"],
    ["", "", "Rock Golem Saddle"],
    ["", "", "Terror Bird Saddle"],
  ],
};

export default tableData;

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    color: var(--text-bright);
}

a{
    text-decoration: none;
    color:initial;
}

button{
    background-color: transparent;
    border:none;
    cursor: pointer;
}

main{
    min-height: 80vh;
}

p{
    font-size: 16px;
    margin-bottom: 16px;
}
`;

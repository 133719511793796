import React, { useEffect } from "react";

const isBrowser = typeof window !== "undefined";

export const GlobalStateContext = React.createContext();

const initialState = {
  darkMode: false,
  data: {},
  elements: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "CHANGE_THEME": {
      if (isBrowser) {
        localStorage.setItem("theme", JSON.stringify(action.value));
      }
      return { ...state, darkMode: action.value };
    }
    case "CHANGE_DATA": {
      return {
        ...state,
        data: action.value,
      };
    }
    case "SET_DATA": {
      return {
        ...state,
        data: { ...state.data, [`item${action.index}`]: action.value },
      };
    }
    case "RESET_ELEMENTS": {
      return {
        ...state,
        elements: [],
      };
    }
    case "REMOVE_DATA": {
      const { value } = action;
      const { [value]: removedItem, ...newObj } = state.data;
      return {
        ...state,
        data: { ...newObj },
      };
    }
    case "SET_ELEMENTS": {
      return {
        ...state,
        elements: [...state.elements, action.value],
      };
    }
    case "REMOVE_ELEMENTS": {
      const newArray = [...state.elements];
      const index = newArray.findIndex((e) => e.props.index === action.value);
      newArray.splice(index, 1);
      return {
        ...state,
        elements: [...newArray],
      };
    }
    default:
      throw new Error("Bad action type");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  useEffect(() => {
    try {
      const isDark = JSON.parse(localStorage.getItem("theme"));
      if (typeof isDark === "boolean") {
        dispatch({ type: "CHANGE_THEME", value: isDark });
      }
    } catch (error) {
      console.log("Theme from localstorage load fail", error);
    }
  }, []);
  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;

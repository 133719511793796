import React from "react";
import GlobalContextProvider from "./src/context/GlobalContextProvider";
import GlobalStyle from "./src/styles/global";

export const wrapRootElement = ({ element }) => {
  return (
    <GlobalContextProvider>
      <GlobalStyle />
      {element}
    </GlobalContextProvider>
  );
};
